import { AuthOptions } from "@aws-amplify/auth/lib-esm/types";
import { ICookieStorageData } from "amazon-cognito-identity-js";
import {
  AMPLIFY_STORAGE_BUCKET,
  COGNITO_AWS_REGION,
  COGNITO_CLIENT_ID,
  COGNITO_COOKIE_DOMAIN,
  COGNITO_IDENTITY_POOL_ID,
  COGNITO_USER_POOL_ID,
} from "../constants";

interface IAmplifyParams {
  cognitoRegion: string;
  cognitoUserPoolId: string;
  cognitoUserPoolWebClientId: string;
  cognitoIdentityPoolId: string;
  cookieDomain: string;
  storageBucket: string;
}

interface IAmplifyStorage {
  AWSS3: {
    bucket: string;
    region: string;
  };
}

export interface IAmplifyConfig extends AuthOptions {
  Auth: {
    region: string;
    userPoolId: string;
    userPoolWebClientId: string;
    authenticationFlowType: string;
    identityPoolId: string;
  };
  cookieStorage: ICookieStorageData;
  Storage: IAmplifyStorage;
}

export const amplifyConfig = (amplifyParams?: IAmplifyParams) => {
  // use supplied value first else default to the Next.js env name used in Launcher
  const region = amplifyParams?.cognitoRegion || COGNITO_AWS_REGION;
  const userPoolId = amplifyParams?.cognitoUserPoolId || COGNITO_USER_POOL_ID;
  const userPoolWebClientId =
    amplifyParams?.cognitoUserPoolWebClientId || COGNITO_CLIENT_ID;
  const identityPoolId =
    amplifyParams?.cognitoIdentityPoolId || COGNITO_IDENTITY_POOL_ID;
  const domain = amplifyParams?.cookieDomain || COGNITO_COOKIE_DOMAIN;
  const bucket = amplifyParams?.storageBucket || AMPLIFY_STORAGE_BUCKET;

  if (!region || !userPoolId || !userPoolWebClientId || !domain) {
    throw new Error("Invalid Amplify config supplied");
  }

  const isLocal = ["127.0.0.1", "localhost"].includes(domain);

  const config: IAmplifyConfig = {
    Auth: {
      region,
      userPoolId,
      userPoolWebClientId,
      authenticationFlowType: "USER_SRP_AUTH",
      identityPoolId,
    },
    cookieStorage: {
      domain,
      path: "/",
      expires: 365,
      sameSite: "lax" as const,
      secure: !isLocal,
    },
    Storage: {
      AWSS3: {
        bucket,
        region,
      },
    },
  };

  return config;
};
