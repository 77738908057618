export const COGNITO_AWS_REGION =
  process.env.COGNITO_AWS_REGION ||
  (process.env.NEXT_PUBLIC_COGNITO_AWS_REGION as string);
export const COGNITO_USER_POOL_ID =
  process.env.COGNITO_USER_POOL_ID ||
  (process.env.NEXT_PUBLIC_COGNITO_USER_POOL_ID as string);
export const COGNITO_CLIENT_ID =
  process.env.COGNITO_CLIENT_ID ||
  (process.env.NEXT_PUBLIC_COGNITO_CLIENT_ID as string);
export const COGNITO_IDENTITY_POOL_ID =
  process.env.COGNITO_IDENTITY_POOL_ID ||
  (process.env.NEXT_PUBLIC_COGNITO_IDENTITY_POOL_ID as string);
export const COGNITO_COOKIE_DOMAIN =
  process.env.COGNITO_COOKIE_DOMAIN ||
  (process.env.NEXT_PUBLIC_COGNITO_COOKIE_DOMAIN as string);
export const AMPLIFY_STORAGE_BUCKET =
  process.env.AMPLIFY_STORAGE_BUCKET ||
  (process.env.NEXT_PUBLIC_AMPLIFY_STORAGE_BUCKET as string);
