import Amplify from "@aws-amplify/core";
import Auth from "@aws-amplify/auth";
import {
  CognitoUser,
  CognitoUserSession,
  CookieStorage,
} from "amazon-cognito-identity-js";
import { jwtVerify, createRemoteJWKSet } from "jose";
import { amplifyConfig } from "@skylark/lib";
import {
  AuthenticatePayload,
  ForgotPasswordPayload,
  SetPasswordPayload,
} from "../interfaces/AuthenticatePayload";

const config = amplifyConfig();

Amplify.Logger.LOG_LEVEL = "INFO";

const jwtSignerUrl = new URL(
  `https://cognito-idp.${config.Auth.region}.amazonaws.com/${config.Auth.userPoolId}/.well-known/jwks.json`
);

Amplify.configure(config);

const clearCookies = () => {
  const cookieStorage = new CookieStorage(config.cookieStorage);
  return cookieStorage.clear();
};

export const signIn = ({
  email,
  password,
}: AuthenticatePayload): Promise<CognitoUser | any> => {
  clearCookies();
  return Auth.signIn(email, password);
};

export const signOut = async (): Promise<void> => {
  await Auth.signOut();
  clearCookies();
};

export const forgotPassword = ({
  email,
}: ForgotPasswordPayload): Promise<any> => Auth.forgotPassword(email);

export const forgotPasswordSubmit = ({
  username,
  code,
  password,
}: SetPasswordPayload): Promise<string> =>
  Auth.forgotPasswordSubmit(username, code, password);

interface SignInChallenge {
  challengeName: string;
}

export const confirmSignUp = ({
  username,
  code,
  password,
}: SetPasswordPayload): Promise<any> => {
  clearCookies();
  return Auth.signIn(username, code).then((user) => {
    if ((user as SignInChallenge)?.challengeName === "NEW_PASSWORD_REQUIRED") {
      return Auth.completeNewPassword(user, password);
    }
    throw new Error("NEW_PASSWORD_REQUIRED was not detected");
  });
};

interface ExtendedCognitoUserSession extends CognitoUserSession {
  getClockDrift(): number;
}

export const validateSession = async (): Promise<CognitoUser | undefined> => {
  const session = (await Auth.currentSession()) as ExtendedCognitoUserSession;
  const user = (await Auth.currentAuthenticatedUser()) as CognitoUser;
  if (session.isValid()) {
    const JWKS = createRemoteJWKSet(jwtSignerUrl);
    await jwtVerify(session.getAccessToken().getJwtToken(), JWKS, {
      clockTolerance: session.getClockDrift(),
    });
  } else {
    return new Promise((resolve, reject) => {
      user.refreshSession(session.getRefreshToken(), (err: Error) => {
        if (err) {
          reject(err);
        }
        Auth.currentAuthenticatedUser().then(resolve).catch(reject);
      });
    });
  }
  return user;
};

export const isUserAuthenticated = async (): Promise<boolean> => {
  try {
    await validateSession();
    return true;
  } catch {
    return false;
  }
};

export const getIdToken = async (): Promise<string> => {
  // currentSession will automatically refresh the idToken
  const session = await Auth.currentSession();
  const token = session.getIdToken().getJwtToken();
  return token;
};
